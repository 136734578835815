<template>
  <div class="page-container">
    <!--详情-->
    <div class="edit-container flex flex-dr flex-center">
      <el-form label-width="130px" ref="entityInfoForm" :model="entityInfo.edit" :rules="entityInfo.formRules">
        <el-form-item label="学期:" prop="semesterid">
          <el-select v-model="entityInfo.edit.semesterid" :disabled="entityInfo.type==='edit'" style="width: 400px">
            <el-option v-for="item in lists.searchFilter.filter[0].data" :value="item.value" :label="item.label"
                       :key="item.value" v-if="item.value!==''">
              <div class="flex flex-between">
                <span>{{ item.label }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级:" prop="clazzIds">
          <el-select v-model="entityInfo.edit.clazzIds" multiple placeholder="请选择班级"
                     :disabled="entityInfo.type==='edit'"
                     style="width: 400px">
            <el-option v-for="(item,index) in lists.searchFilter.filter[1].data" :value="item.value" :key="index"
                       :label="item.label" v-if="item.value!==''"
                       @change="v=>EntityInfoMethods().clazzChange(v)"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="实验课程:" prop="experimentIds">
          <el-select v-model="entityInfo.edit.experimentIds" multiple placeholder="请选择实验课程" style="width: 400px"
                     :disabled="entityInfo.type==='edit'" @change="v=>EntityInfoMethods().onExperimentChange(v)">
            <el-option v-for="(item,index) in lists.searchFilter.filter[2].data" :value="item.value" :key="index"
                       :label="item.label" v-if="item.value!==''"></el-option>
          </el-select>
          <div class="experiment-des" v-if="entityInfo.chooseExperimentInfo.length>0">
            <div style="margin-bottom: 5px">课程有效期：</div>
            <div v-for="item in entityInfo.chooseExperimentInfo" style="margin-bottom: 5px">
              {{ item.name }}：{{
                item.isForver ? '永久' : item.startDate + "-" + item.endDate
              }}
            </div>
          </div>
        </el-form-item>
        <el-form-item label="实验id:" v-if="entityInfo.type==='edit'&&false">
          <span>{{ entityInfo.edit.experimentIds }}</span>
        </el-form-item>
        <el-form-item label="实验开放时间" prop="date" v-if="entityInfo.edit.experimentIds">
          <el-date-picker
              v-model="entityInfo.edit.date"
              :disabled="entityInfo.type!=='add'&&entityInfo.edit.experimentstatus===2"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00','23:59:59']"
              value-format="yyyy-MM-dd HH:mm:ss"
              :picker-options="entityInfo.datePickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="可重复实验次数" prop="repeatabletimes">
          <el-input style="width: 400px" v-model.number="entityInfo.edit.repeatabletimes" type="number"
                    :disabled="entityInfo.type!=='add'&&entityInfo.edit.experimentstatus===2"
                    placeholder="请输入次数"
                    maxlength="4"></el-input>
        </el-form-item>
        <el-form-item label="实验安排备注" prop="remarks">
          <el-input style="width: 400px" v-model="entityInfo.edit.remarks"
                    placeholder="请输入备注"></el-input>
        </el-form-item>
        <el-form-item label="班级实验权限" prop="experimentalpermissions">
          <div class="flex flex-between">
            <el-switch
                :disabled="entityInfo.type!=='add'&&entityInfo.edit.experimentstatus===2"
                v-model="entityInfo.edit.experimentalpermissions"
                active-text="开"
                inactive-text="关">
            </el-switch>
            <el-button style="margin-right: 60px" v-if="entityInfo.type==='add'" type="text"
                       @click="AddSetAuthMethods().clickSetAuthBtn()">管理单个学生实验权限
            </el-button>
            <el-button style="margin-right: 60px" v-if="entityInfo.type==='edit'"
                       :disabled="entityInfo.edit.experimentstatus===2" type="text"
                       @click="ResetTimesMethods().clickResetExperimentTimesBtn(entityInfo.edit)">管理单个学生实验权限
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <div class="buttons">
        <el-button type="default"
                   @click="$router.go(-1)">取 消
        </el-button>
        <el-button type="primary" v-if="entityInfo.type==='add'"
                   @click="EntityInfoMethods().clickAddBtn()" :loading="saveOrEditDoing">确 认
        </el-button>
        <el-button type="primary" v-if="entityInfo.type==='edit'&&entityInfo.edit.experimentstatus!==2"
                   :loading="saveOrEditDoing"
                   @click="EntityInfoMethods().clickEditBtn()">确 认
        </el-button>
      </div>
    </div>
    <!--弹窗-重置实验次数-->
    <el-dialog
        :close-on-click-modal="false"
        :title="resetTimesInfo.title"
        :visible.sync="resetTimesInfo.dialog"
        width="900px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <!--搜索栏-->
        <div class="search-box flex flex-start" style="margin-bottom: 30px">
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 80px">行政班级：</div>
            <el-select v-model="resetTimesInfo.lists.query.administrationClazzId">
              <el-option v-for="item in resetTimesInfo.administrationClazzFilterArr" :label="item.label"
                         :value="item.value"
                         :key="item.value"></el-option>
            </el-select>
          </div>
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 70px;">账 号：</div>
            <el-input v-model="resetTimesInfo.lists.query.account"></el-input>
          </div>
          <el-button type="primary" @click="ResetTimesMethods().clickSearchBtn()">查找学生</el-button>
        </div>
        <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="resetTimesInfo.lists.list" fit
                  v-loading="resetTimesInfo.lists.loading"
                  height="600px"
                  style="width: 100%;"
                  @selection-change="v=>ResetTimesMethods().onSelected(v)">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column label="账号">
            <template slot-scope="scope">
              <span>{{ scope.row.studentaccount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.studentname }}</span>
            </template>
          </el-table-column>
          <el-table-column label="行政班级" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.gradeName }} - {{ scope.row.administrationClazzName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实验权限" align="center">
            <template slot-scope="scope">
              <el-switch
                  @change="v=>ResetTimesMethods().toggleAuth(scope.row,v)"
                  v-model="scope.row.experimentauth"
                  active-text="开"
                  inactive-text="关">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="重置实验次数" align="center">
            <template slot-scope="scope">
              <el-button size="small" type="text" @click="ResetTimesMethods().clickResetBtn('single',scope.row.id)">
                重置
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <!--列表分页-->
        <div class="flex flex-between" style="margin-top: 20px;">
          <div style="margin-top: 10px">
            <span style="color:#409eff">已选{{ resetTimesInfo.listSelected.length }}项</span>
          </div>
          <div class="pagination-container">
            <el-pagination background @current-change="(number)=>ResetTimesMethods().pageChange(number)"
                           :current-page.sync="resetTimesInfo.lists.pages.number"
                           :page-size.sync="resetTimesInfo.lists.pages.size"
                           layout="total,prev, pager, next,sizes,jumper"
                           :total="resetTimesInfo.lists.pages.totalElements"
                           :page-sizes="[5,10,20,50,100,200]"
                           @size-change="(size)=>ResetTimesMethods().pageLimitChange(size)"
                           :page-count="resetTimesInfo.lists.pages.totalPages">
            </el-pagination>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button type="primary" v-if="resetTimesInfo.listSelected.length>0" size="small"
                     @click="ResetTimesMethods().clickResetBtn('multiple')">批量重置实验次数
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!--弹窗-新增实验时关联权限-->
    <el-dialog
        :close-on-click-modal="false"
        title="设置学生实验权限"
        :visible.sync="addSetAuth.dialog"
        width="1000px"
        center
        v-el-drag-dialog>
      <div class="dialog-container">
        <!--搜索栏-->
        <div class="search-box flex flex-start">
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 80px">行政班级：</div>
            <el-select v-model="entityInfo.lists.query.administrationClazzId">
              <el-option v-for="item in addSetAuth.administrationClazzFilterArr" :label="item.label" :value="item.value"
                         :key="item.value"></el-option>
            </el-select>
          </div>
          <div class="input-box flex flex-start" style="margin-right: 20px">
            <div style="width: 70px;">账 号：</div>
            <el-input v-model="entityInfo.lists.query.account"></el-input>
          </div>
          <el-button type="primary" @click="AddSetAuthMethods().clickSearchBtn()">查找学生</el-button>
        </div>
        <el-table :header-cell-style="{background:'#fafafa',color:'#555'}" :data="addSetAuth.list" fit
                  style="width: 100%;margin-top: 25px" max-height="500"
                  @selection-change="v=>AddSetAuthMethods().onSetAuthSelected(v)">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column label="账号">
            <template slot-scope="scope">
              <span>{{ scope.row.account }}</span>
            </template>
          </el-table-column>
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>
          <el-table-column label="教学班级" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.clazzname }}</span>
            </template>
          </el-table-column>
          <el-table-column label="行政班级" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.gradename }} - {{ scope.row.administrationClazzName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="实验权限" align="center">
            <template slot-scope="scope">
              <el-switch
                  @change="v=>AddSetAuthMethods().authChange(v,scope.row.id)"
                  v-model="scope.row.experimentauth"
                  active-text="开"
                  inactive-text="关">
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 5px" v-if="addSetAuth.listSelected.length>0">
          <span style="color:#409eff">已选{{ addSetAuth.listSelected.length }}项</span>
        </div>
        <div class="dialog-footer" style="text-align: center">
          <el-button type="primary" v-if="addSetAuth.listSelected.length>0" size="small"
                     @click="AddSetAuthMethods().clickMultipleToggleAuthBtn('open')">批量开实验权限
          </el-button>
          <el-button type="primary" v-if="addSetAuth.listSelected.length>0" size="small"
                     @click="AddSetAuthMethods().clickMultipleToggleAuthBtn('close')">批量关实验权限
          </el-button>
        </div>
        <div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 20px">
          <el-button type="default"
                     @click="addSetAuth.dialog=false">确定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/**
 * todo
 * 单独权限编辑时也可以修改
 */
import elDragDialog from "@/directive/el-drag-dialog";
import {date_format, find_obj_from_arr_by_id, getQuery, objectToLVArr, validateMaxLength} from "@/utils/common";
import {mapState} from "vuex";
import {EnumsModel} from "@/model/EnumsModel";
import {msg_confirm, msg_err, msg_success} from "@/utils/ele_component";
import {SemesterModel} from "@/model/exp/SemesterModel";
import {StudentModel} from "@/model/exp/StudentModel";
import {ExperimentModel} from "@/model/exp/ExperimentModel";
import {CommonModel} from "@/model/CommonModel";
import {ClazzModel} from "@/model/exp/ClazzModel";
import {RouterModel} from "@/model/RouterModel";
import {AdministrationClazzModel} from "@/model/exp/AdministrationClazzModel";
import {UserModel} from "@/model/exp/UserModel";

export default {
  name: "teacherExperimentInfo",
  directives: {
    elDragDialog
  },
  // 过滤器
  filters: {
    dateFormat(value) {
      if (value) {
        return date_format(value, "yyyy/MM/dd HH:mm")
      }
      return ""
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user
    })
  },
  data() {
    // 校检电话号码
    const validateTimes = (rule, value, callback) => {
      if(getQuery("numberLimit")==='false'){
        callback()
      }
      let reg = /^[0-9]+$/
      if (!reg.test(value)) {
        callback(new Error('请输入1-10之间的整数'))
      }
      if (value < 1 || value > 10) {
        callback(new Error('请输入1-10之间的整数'))
      }
      callback()
    }
    return {
      // 外部方法
      date_format: date_format,
      find_obj_from_arr_by_id: find_obj_from_arr_by_id,
      // 枚举
      enums: EnumsModel,
      // 列表
      lists: {
        list: [],
        loading: false,
        query: {},
        queryBase: {},
        pages: {
          size: 20
        },
        searchFilter: {
          search: [],
          filter: [
            {
              type: 'select',
              label: '学期',
              key: 'semesterid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '班级',
              key: 'clazzid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验课程',
              key: 'experimentid',
              value: '',
              data: [],
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
            {
              type: 'select',
              label: '实验状态',
              key: 'experimentalstatus',
              value: '',
              data: objectToLVArr(EnumsModel.experimentStatus, true),
              dataObject: {},
              dataOrigin: [],// 存储数据库返回的默认列表
              change: function (value) {
              }
            },
          ]
        }
      },
      // 实体详情
      entityInfo: {
        title: "新增实验安排",
        type: "add",
        dialog: false,
        filter: [],
        lists: {
          selectedList: [],// 选择的列表
          list: [],
          loading: false,
          query: {},
          queryBase: {},
          pages: {
            size: 20
          },
        },
        edit: {
          repeatabletimes: 5,
          experimentalpermissions: true
        },
        // 输入检测
        formRules: {
          'clazzIds': {required: true, message: '请选择班级', trigger: 'change'},
          'semesterid': {required: true, message: '请选择学期', trigger: 'change'},
          'experimentIds': {required: true, message: '请选择实验', trigger: 'change'},
          'repeatabletimes': {required: true, validator: validateTimes},
          'date': {required: true, message: '请选择实验开放时间', trigger: 'change'},
          'remarks': {required: true, message: '请输入实验安排备注', trigger: 'change'},
        },
        // 选择的实验信息
        chooseExperimentInfo: [],
        // 实验授权时间禁止选择时间
        datePickerOptions: {
          disabledDate: (time) => {

          }
        },
      },
      // 重置实验次数列表
      resetTimesInfo: {
        administrationClazzFilterArr: [],
        dialog: false,
        list: [],
        listSelected: [],
        lists: {
          list: [],
          loading: false,
          query: {},
          queryBase: {},
          pages: {
            size: 20
          },
        },
      },
      // 新增时-设置学生
      addSetAuth: {
        dialog: false,
        list: [],
        listSelected: [],
        setedList: {},
        administrationClazzFilterArr: [],
      },
      // 保存或新增加载
      saveOrEditDoing: false,
    }
  },
  async mounted() {
    let type = this.$route.query["type"]
    this.entityInfo.type = type
    if (type === "add") {
      setTimeout(() => {
        this.$refs['entityInfoForm'].clearValidate()
      }, 200)
    }
    if (type === "edit") {
      let entity = localStorage.getItem("experimentScheduleInfo")
      if (entity) {
        entity = JSON.parse(entity)
        entity.experimentIds = [entity.experimentid]
        entity.clazzIds = [entity.clazzid]
        this.entityInfo.edit = entity
      } else {
        msg_confirm("获取实验详情出错")
      }
    }
    // 初始化筛选
    this.ListMethods().initFilter()
  },
  methods: {
    // 列表Methods
    ListMethods() {
      let $this = this
      return {
        // 初始化筛选列表
        async initFilter(type) {
          // 获取学期列表
          if (!type || type === 0) {
            let semesterList = await SemesterModel.getList(0, 0, {})
            let generateListFilter0 = CommonModel.generateListFilterOptions('name', 'id', semesterList, false)
            // 新增时 默认选中当前学期功能，如果没有就选中最近学期
            let defaultSemesterId = ""
            for (let i = 0; i < semesterList.length; i++) {
              let li = semesterList[i]
              generateListFilter0[0][i].label = li.name + " (" + date_format(li.startTime, "yyyy/MM/dd") + "-" + date_format(li.endTime, "yyyy/MM/dd") + ")"
              if ($this.entityInfo.type === "add") {
                if (li.iscurrentsemester) {// 如果有当前时间的学期
                  defaultSemesterId = li.id
                }
                if (li.isdefaultselected && !defaultSemesterId) {// 如果有最近的学期
                  defaultSemesterId = li.id
                }
                if (!li.isdefaultselected && !defaultSemesterId) {// 如果没有最近的学期
                  defaultSemesterId = li.id
                }
              }
            }
            if ($this.entityInfo.type === "add") {
              $this.$set($this.entityInfo.edit, "semesterid", defaultSemesterId)
            }
            $this.$set($this.lists.searchFilter.filter[0], "data", generateListFilter0[0])
            $this.$set($this.lists.searchFilter.filter[0], "dataObject", generateListFilter0[1])
            $this.$set($this.lists.searchFilter.filter[0], "dataOrigin", semesterList)

          }
          // 获取班级列表
          if (!type || type === 1) {
            let clazzList = (await ClazzModel.getList(1, -1, {}))[0]
            let dataArr = []
            clazzList.forEach(li => {
              dataArr.push({
                label: li.clazzName,
                value: li.clazzid
              })
            })
            $this.$set($this.lists.searchFilter.filter[1], "data", dataArr)
            $this.$set($this.lists.searchFilter.filter[1], "dataOrigin", clazzList)
          }
          // 获取实验列表
          if (!type || type === 2) {
            let experimentList = (await ExperimentModel.getTeacherExperimentList($this.userInfo))
            // 如果是新增时，筛选出有实验权限的实验
            if ($this.entityInfo.type === "add") {
              experimentList = experimentList.filter((li) => {
                // 只返回有实验权限的 todo 只在前端限制后台设置的没权限的，不安全
                return li.authstatus === true
              })
            }

            let generateListFilter1 = CommonModel.generateListFilterOptions('name', 'id', experimentList, false)
            $this.$set($this.lists.searchFilter.filter[2], "data", generateListFilter1[0])
            $this.$set($this.lists.searchFilter.filter[2], "dataObject", generateListFilter1[1])
            $this.$set($this.lists.searchFilter.filter[2], "dataOrigin", experimentList)
            if ($this.entityInfo.type === 'edit') {
              $this.EntityInfoMethods().onExperimentChange([$this.entityInfo.edit.experimentid])
            }
          }

          $this.$forceUpdate()
        },
      }
    },
    // 实体Methods
    EntityInfoMethods() {
      let $this = this;
      return {
        // 实验被改变
        async onExperimentChange(v) {
          let experiments = []
          v.forEach(li => {
            // 找到实验
            let experiment = find_obj_from_arr_by_id("id", li, $this.lists.searchFilter.filter[2].dataOrigin)[1]
            experiment.startDate = date_format(experiment.authorizedusestime, "yyyy/MM/dd HH:mm")
            experiment.endDate = date_format(experiment.authorizeduseetime, "yyyy/MM/dd HH:mm")
            experiment.isForver = experiment.authorizedusetimestatus
            experiments.push(experiment)
          })
          $this.$set($this.entityInfo, "chooseExperimentInfo", experiments)
        },
        // 班级被改变
        async clazzChange(v) {
          $this.$set($this.addSetAuth, "list", [])
          $this.$set($this.addSetAuth, "listSelected", [])
        },
        // 点击新增按钮
        async clickAddBtn() {
          $this.$refs['entityInfoForm'].validate(async validate => {
            if (validate) {
              $this.saveOrEditDoing = true
              // 构建参数
              let entity = JSON.parse(JSON.stringify($this.entityInfo.edit))
              if (new Date(entity.date[1]).getTime() <= new Date(entity.date[0]).getTime()) {
                msg_err("开始时间和结束时间不能选择同一天")
                return
              }
              entity.openstarttime = entity.date[0]
              entity.openendtime = entity.date[1]
              // 设置单独设置学生权限列表
              let studentExperimentEntities = []
              if ($this.addSetAuth.setedList) {// 判断是否单独设置过学生
                for (let id in $this.addSetAuth.setedList) {
                  if ($this.addSetAuth.setedList.hasOwnProperty(id)) {
                    let li = {}
                    li.id = id
                    li.experimentauth = $this.addSetAuth.setedList[id]
                    studentExperimentEntities.push(li)
                  }
                }
              }
              entity.studentExperimentEntities = studentExperimentEntities
              console.log(JSON.stringify(entity))
              if (await ExperimentModel.save(entity).catch(() => {
                $this.saveOrEditDoing = false
              })) {
                msg_success('新增成功')
                //$this.lists.list.push(newData)
                $this.$router.go(-1)
                $this.entityInfo.dialog = false
              }
              $this.saveOrEditDoing = false
            }
          })
        },
        // 点击编辑按钮
        async clickEditBtn() {
          $this.$refs['entityInfoForm'].validate(async validate => {
            if (validate) {

              // 构建参数
              if (await msg_confirm('确认要修改该实验安排吗？')) {
                $this.saveOrEditDoing = true

                let entity = JSON.parse(JSON.stringify($this.entityInfo.edit))
                if (new Date(entity.date[1]).getTime() <= new Date(entity.date[0]).getTime()) {
                  msg_err("开始时间和结束时间不能选择同一天!")
                  return
                }
                entity.openstarttime = entity.date[0]
                entity.openendtime = entity.date[1]

                // 设置单独设置学生权限列表 todo
                let studentExperimentEntities = []
                if ($this.addSetAuth.list.length > 0) {// 判断是否单独设置过学生
                  $this.addSetAuth.list.forEach(li => {// 构建
                    if (!li.hasOwnProperty("experimentauth")) {
                      li.experimentauth = false
                    }
                    li.userid = li.id
                    li.studentaccount = li.account
                    delete li.id
                    studentExperimentEntities.push(li)
                  })
                }
                entity.studentExperimentEntities = studentExperimentEntities
                if (await ExperimentModel.update(entity).catch(() => {
                  $this.saveOrEditDoing = false
                })) {
                  msg_success('修改成功')
                  $this.$router.go(-1)
                  $this.entityInfo.dialog = false
                }

                $this.saveOrEditDoing = false
              }
            }
          });
        },
      }
    },
    // 新增时-设置学生实验权限弹窗
    AddSetAuthMethods() {
      let $this = this;
      return {
        // 点击管理单个学生实验权限按钮-新增实验安排时
        async clickSetAuthBtn() {
          // 获取班级学生列表
          if ($this.entityInfo.edit.clazzIds.length > 0) {
            $this.addSetAuth.clazzid = $this.entityInfo.edit.clazzIds
            $this.$set($this.entityInfo.lists.query, "account", "")
            $this.$set($this.entityInfo.lists.query, "administrationClazzId", [])
            let list = (await StudentModel.getList(1, -1, {
              inClazzIds: $this.entityInfo.edit.clazzIds
            }))[0]
            let administrationClazzFilterObject = {};
            // 默认所有学生权限开 生成行政班级筛选表
            for (let i = 0; i < list.length; i++) {
              list[i]["experimentauth"] = true
              // 从已设置权限的列表搜索
              for (let id in $this.addSetAuth.setedList) {
                if ($this.addSetAuth.setedList.hasOwnProperty(id)) {
                  if (id === list[i]["id"]) {
                    list[i]["experimentauth"] = $this.addSetAuth.setedList[id]
                  }
                }
              }
              administrationClazzFilterObject[list[i].administrationClazzId] = list[i].administrationClazzName
            }
            $this.addSetAuth.list = list
            $this.addSetAuth.dialog = true
            // 设置行政班筛选数组
            let administrationClazzFilterArr = [];
            for (let i in administrationClazzFilterObject) {
              if (administrationClazzFilterObject.hasOwnProperty(i)) {
                administrationClazzFilterArr.push({
                  label: administrationClazzFilterObject[i],
                  value: i
                })
              }
            }
            $this.addSetAuth.administrationClazzFilterArr = administrationClazzFilterArr
          } else {
            msg_err("请先选择班级")
            return false
          }
        },
        // 行政班级名称自动搜索
        async clazzNameAutoSearch(query, callback) {
          let list = await AdministrationClazzModel.conditionQueryList(query, $this.userInfo.schoolId)
          let listResult = []
          list.forEach(li => {
            listResult.push({
              value: li.name
            })
          })
          callback(listResult)
        },
        // 点击搜索按钮
        clickSearchBtn() {
          this.getList(1, $this.entityInfo.lists.pages.size, $this.entityInfo.lists.query)
        },
        // 获取教学班学生列表
        async getList(page, size, query) {
          query = Object.assign(query, $this.entityInfo.lists.queryBase)
          query.inClazzIds = $this.entityInfo.edit.clazzIds;
          $this.entityInfo.lists.loading = true;
          let [list] = await StudentModel.getList(1, -1, query)
          // 默认所有学生权限开
          for (let i = 0; i < list.length; i++) {
            list[i]["experimentauth"] = true
            // 从已设置权限的列表搜索
            for (let id in $this.addSetAuth.setedList) {
              if ($this.addSetAuth.setedList.hasOwnProperty(id)) {
                if (id === list[i]["id"]) {
                  list[i]["experimentauth"] = $this.addSetAuth.setedList[id]
                }
              }
            }
          }
          $this.addSetAuth.list = list
          $this.entityInfo.lists.loading = false
        },
        // 单个管理学生实验权限-选中
        onSetAuthSelected(v) {
          $this.addSetAuth.listSelected = v;
        },
        // 单个切换
        authChange(v, id) {
          $this.addSetAuth.setedList[id] = v
        },
        // 批量管理学生实验权限-切换
        clickMultipleToggleAuthBtn(type) {
          let result = type === 'open'
          $this.addSetAuth.listSelected.forEach(liS => {
            $this.addSetAuth.list.forEach((li, index) => {
              if (li.id === liS.id) {
                $this.$set($this.addSetAuth.list[index], "experimentauth", result)
              }
            })
            // 只记录关权限的
            if (type !== "open") {
              $this.addSetAuth.setedList[liS.id] = false
            } else {
              $this.addSetAuth.setedList[liS.id] = true
            }
          })
        },
      }
    },
    // 重置实验次数弹窗Methods
    ResetTimesMethods() {
      let $this = this;
      return {
        // 点击重置实验次数按钮
        async clickResetExperimentTimesBtn(entity) {
          $this.resetTimesInfo.scheduleId = entity.id
          $this.resetTimesInfo.title = entity.clazzname + "  " + entity.experimentname
          $this.$set($this.resetTimesInfo.lists.query, "scheduleId", entity.id)
          $this.$set($this.resetTimesInfo.lists.query, "account", "")
          $this.$set($this.resetTimesInfo.lists.query, "administrationClazzId", [])
          // 获取班级学生列表
          $this.resetTimesInfo.dialog = true
          $this.resetTimesInfo.lists.loading=true
          let listResult = (await ExperimentModel.getResetExperimentTimesList(1, 50, $this.resetTimesInfo.lists.query).catch(res=>{
            $this.resetTimesInfo.lists.loading=false
          }))
          let list=listResult[0]
          // 设置行政班筛选数组
          let administrationClazzFilterObject = {};
          for (let i = 0; i < list.length; i++) {
            // 默认所有学生权限开 生成行政班级筛选表
            administrationClazzFilterObject[list[i].administrationClazzId] = list[i].administrationClazzName
          }
          let administrationClazzFilterArr = [];
          for (let i in administrationClazzFilterObject) {
            if (administrationClazzFilterObject.hasOwnProperty(i)) {
              administrationClazzFilterArr.push({
                label: administrationClazzFilterObject[i],
                value: i
              })
            }
          }
          $this.resetTimesInfo.lists.list = list
          $this.resetTimesInfo.lists.pages = listResult[1]
          $this.resetTimesInfo.administrationClazzFilterArr = administrationClazzFilterArr
          $this.resetTimesInfo.lists.loading=false
        },
        // 选择某些项
        onSelected(v) {
          $this.resetTimesInfo.listSelected = v;
        },
        async getList(page, size, query) {
          $this.resetTimesInfo.lists.loading = true;
          [$this.resetTimesInfo.lists.list, $this.resetTimesInfo.lists.pages] = await ExperimentModel.getResetExperimentTimesList(page, size, query)
          $this.resetTimesInfo.lists.loading = false
        },
        // 点击搜索按钮
        async clickSearchBtn() {
          let query = {
            scheduleId: $this.resetTimesInfo.scheduleId
          }
          if ($this.resetTimesInfo.lists.query.account) {
            query.account = $this.resetTimesInfo.lists.query.account
          }
          if ($this.resetTimesInfo.lists.query.administrationClazzId) {
            query.administrationClazzId = $this.resetTimesInfo.lists.query.administrationClazzId
          }
          // 获取班级学生列表
          this.getList(1, $this.resetTimesInfo.lists.pages.size, query)
        },
        // 分页-改变页码
        async pageChange(page) {
          this.getList(page, $this.resetTimesInfo.lists.pages.size, $this.resetTimesInfo.lists.query)
        },
        // 分页-改变每页显示数量
        async pageLimitChange(size) {
          this.getList($this.resetTimesInfo.lists.pages.number, size, $this.resetTimesInfo.lists.query)
        },
        // 点击批量重置实验次数按钮
        async clickResetBtn(type, id) {
          let ids = []
          if (type === 'multiple') { // 批量
            if (await msg_confirm("确定要重置这些学生的实验次数吗?")) {
              ids = $this.resetTimesInfo.listSelected.map(v => {
                return v.id
              })
              if (await ExperimentModel.resetExperimentTimesMultiple(ids, $this.resetTimesInfo.experimentNum)) {
                msg_success("批量重置实验次数成功")
              }
            }
          }
          if (type === 'single') { // 单个
            ids = [id]
            if (await ExperimentModel.resetExperimentTimesMultiple(ids, $this.resetTimesInfo.experimentNum)) {
              msg_success("重置实验次数成功")
            }
          }
        },
        // 切换实验权限
        async toggleAuth(v) {
          if (await ExperimentModel.updateExperiment(v)) {
            msg_success("修改实验权限成功")
          }
        },
      }
    },
  }
}
</script>

<style scoped lang="less">
// 实验介绍
.experiment-des {
  margin-top: 8px;
  font-size: 14px;
  line-height: 14px;
  color: #999999;
}

.edit-container {

}

.buttons {
  text-align: center;
}

</style>
